<template>
    <slot name="activator" :show="show" :hide="hide"></slot>
    <Teleport :to="teleportTarget">
        <div
            v-bind="$attrs"
            ref="offcanvasRef"
            class="offcanvas offcanvas-generic"
            :id="id" 
            :class="{
                ['offcanvas-' + placement]: true
            }"
            v-on="{
                'show.bs.offcanvas': onShow,
                'shown.bs.offcanvas': onShown,
                'hide.bs.offcanvas': onHide,
                'hidden.bs.offcanvas': onHidden
            }"
            tabindex="-1"
            :aria-labelledby="(title && id) ? 'offcanvasLabel-' + id : undefined">
            <slot name="header" :hide="hide">
                <div class="offcanvas-header">
                    <slot name="header-left" :hide="hide"></slot>
                    <h5 v-if="title" 
                        class="offcanvas-title"
                        :id="(title && id) ? 'offcanvasLabel-' + id : undefined">
                        {{ title }}
                    </h5>
                    <button type="button" 
                        class="btn btn-overlay-dark rounded-circle translucent-bg p-2 ms-auto" 
                        data-bs-dismiss="offcanvas" 
                        aria-label="Close">
                        <Icon symbol="x" class="icon icon-m"></Icon>
                    </button>
                </div>
            </slot>
            <div class="offcanvas-body" :class="bodyCssClass">
                <slot :show="show" :hide="hide" :hideAsync="hideAsync" :isActive="isActive"></slot>
            </div>
            <div v-if="$slots['footer']" class="offcanvas-footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </Teleport>
</template>

<script lang="ts" setup>
import Offcanvas from "bootstrap/js/dist/offcanvas";
import { onBeforeUnmount, onMounted, PropType, ref } from "vue";
import { Icon } from '@/modules/core/components';
import appConstants from "@/modules/core/app.constants";

const props = defineProps({
    id: String,
    placement: {
        type: String as PropType<"start"|"end"|"top"|"bottom">,
        validator(value: string): boolean {
            return ["start", "end", "top", "bottom"].includes(value);
        },
        default: "start"
    },
    title: String,
    backdrop: {
        type: Boolean,
        default: true
    },
    keyboard: {
        type: Boolean,
        default: true
    },
    scroll: {
        type: Boolean,
        default: false
    },
    bodyCssClass: String,
    eager: Boolean
})

const emit = defineEmits(['show', 'shown', 'hide', 'hidden']);

const isActive = ref(false);
const teleportTarget = appConstants.MODALS_CONTAINER_EL_SELECTOR;
const offcanvasRef = ref<HTMLElement>();
let offcanvasInstance: Offcanvas;

onMounted(() => {
    if (!offcanvasRef.value) return;
    offcanvasInstance = Offcanvas.getOrCreateInstance(offcanvasRef.value, {
        backdrop: props.backdrop,
        keyboard: props.keyboard,
        scroll: props.scroll
    });

    if (props.eager) {
        offcanvasInstance.show();
    }
})

onBeforeUnmount(async () => {
    await hideAsync()
    offcanvasInstance.dispose()
})

const show = () => offcanvasInstance.show()

const hide = () => offcanvasInstance.hide()

const hideAsync = () => new Promise<void>((resolve, reject) => {
    offcanvasRef.value?.addEventListener('hidden.bs.offcanvas', () => resolve(), { once: true });
    hide();
})

const onShow = () => {
    emit('show');
    document.body.classList.add('offcanvas-open');
}

const onHide = () => emit('hide')

const onShown = () => {
    isActive.value = true;
    emit('shown');
}

const onHidden = () => {
    isActive.value = false;
    emit('hidden');
    document.body.classList.remove('offcanvas-open');
}

defineExpose({ show, hide })

</script>
